// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";

export const Contact = (props) => {

  return (
    <div>
      <div id="footer">
        <div className="container text-center">
        <p>
            Copyright &copy; 2024 , {" "}
            <a href="https://eagleeye.rolling-master.com/privacy/" rel="nofollow">
            OkScore.ai , 
            </a>
            All Rights Reserved 
          </p>
        </div>
      </div>
    </div>
  );
};
