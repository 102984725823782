import React, { useState, useEffect } from "react";
import { Navigation } from "./components/en/navigation";
import { Header } from "./components/en/header";
import { About } from "./components/en/about";
import { Team } from "./components/en/Team";

import { PrivacyPolicy } from "./components/en/privacy-policy";
import { UserAgreement } from "./components/en/user-agreement";
import { Contact } from "./components/en/contact";
//PT
import { Navigation as NavigationPT } from "./components/pt/navigation";
import { Header as HeaderPT } from "./components/pt/header";
import { About as AboutPT } from "./components/pt/about";
import { Team as TeamPT } from "./components/pt/Team";

import { PrivacyPolicy as PrivacyPolicyPT } from "./components/pt/privacy-policy";
import { UserAgreement as UserAgreementPT } from "./components/pt/user-agreement";
import { Contact as ContactPT } from "./components/pt/contact";

// CN
import { Navigation as NavigationCN } from "./components/cn/navigation";
import { Header as HeaderCN } from "./components/cn/header";
import { About as AboutCN } from "./components/cn/about";
import { Team as TeamCN} from "./components/cn/Team";

import { PrivacyPolicy as PrivacyPolicyCN } from "./components/cn/privacy-policy";
import { UserAgreement as UserAgreementCN } from "./components/cn/user-agreement";
import { Contact as ContactCN } from "./components/cn/contact";
import JsonData from "./data/data.json";
import JsonDataCN from "./data/dataCN.json";
import JsonDataPT from "./data/dataPT.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [landingPageDataCN, setLandingPageDataCN] = useState({});
  const [landingPageDataPT, setLandingPageDataPT] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    setLandingPageDataCN(JsonDataCN);
    setLandingPageDataPT(JsonDataPT);
  }, []);

  return (
    <Router>
      <div>

        <Switch>
          <Route path="/" exact>
            <Navigation />
            <Header data={landingPageData.Header} />
            <About data={landingPageData.About} />
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />
          </Route>
          <Route path="/en-privacy-policy" exact>
            <Navigation />
            <PrivacyPolicy />
          </Route>
          <Route path="/en-user-agreement" exact>
            <Navigation />
            <UserAgreement />
          </Route>

          <Route path="/pt" exact>
            <NavigationPT />
            <HeaderPT data={landingPageDataPT.Header} />
            <AboutPT data={landingPageDataPT.About} />
            <TeamPT data={landingPageDataPT.Team} />
            <ContactPT data={landingPageDataPT.Contact} />
          </Route>
          <Route path="/pt-privacy-policy" exact>
            <NavigationPT />
            <PrivacyPolicyPT />
          </Route>
          <Route path="/pt-user-agreement" exact>
            <NavigationPT />
            <UserAgreementPT />
          </Route>
          
          <Route path="/cn" exact>
            <NavigationCN />
            <HeaderCN data={landingPageDataCN.Header} />
            <AboutCN data={landingPageDataCN.About} />
            <TeamCN data={landingPageDataCN.Team} />
            <ContactCN data={landingPageDataCN.Contact} />
          </Route>
          <Route path="/cn-privacy-policy" exact>
            <NavigationCN />
            <PrivacyPolicyCN />
          </Route>
          <Route path="/cn-user-agreement" exact>
            <NavigationCN />
            <UserAgreementCN />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
